var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    tw-flex tw-w-full tw-h-auto lg:tw-justify-center tw-items-center\n    lg:tw-flex-row\n  ",staticStyle:{"background":"white"}},[_c('v-col',{staticClass:"tw-hidden lg:tw-flex left-side",attrs:{"sm":"12","lg":"7"}},[_c('v-col',{staticClass:"left-side-body",attrs:{"sm":"12"}},[_c('img',{staticStyle:{"height":"40px","width":"186px"},attrs:{"src":require("@/assets/logos/white.png"),"alt":"logo"}}),_c('label',{staticClass:"buddy"},[_vm._v("Hey Buddy!")]),_c('h6',{staticClass:"welcome tw-mt-5"},[_vm._v("Welcome to Nomadicpod.")]),_c('p',{staticClass:"admin tw-mt-5"},[_vm._v("Mobile Agent Admin Board")])])],1),_c('v-col',{staticClass:"right-side",attrs:{"sm":"12","lg":"5"}},[_c('v-col',{staticClass:"right-side-body",attrs:{"sm":"12"}},[_c('img',{staticClass:"mb-3 mt-6",staticStyle:{"height":"34px","width":"136px"},attrs:{"src":require("@/assets/logos/blue.png"),"alt":"logo"}}),_c('h6',{staticClass:"welcome tw-mt-0"},[_vm._v("Welcome to Nomadicpod.")]),_c('p',{staticClass:"admin tw-mt-1"},[_vm._v("Mobile Agent Admin Board")]),_c('h6',{staticClass:"signup-header mb-1 mb-md-5"},[_vm._v("Sign Up")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.signUpCorporateAgent)}}},[_c('ValidationProvider',{attrs:{"name":"Name of Business","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Name of Business","solo":"","hide-details":"","type":"text","required":"","autofocus":""},model:{value:(_vm.signupData.individualAgentDetails.nameOfBusiness),callback:function ($$v) {_vm.$set(_vm.signupData.individualAgentDetails, "nameOfBusiness", $$v)},expression:"signupData.individualAgentDetails.nameOfBusiness"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Email","solo":"","hide-details":"","type":"email","required":""},model:{value:(_vm.signupData.email),callback:function ($$v) {_vm.$set(_vm.signupData, "email", $$v)},expression:"signupData.email"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"tw-pt-4 mb-2"},[_c('phone-number',{attrs:{"phone-number-exist":_vm.signupData.individualAgentDetails.phoneNumber},on:{"getNumber":_vm.getNumber}})],1),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-text-field',{attrs:{"hide-details":"","append-icon":_vm.value ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value ? 'text' : 'password',"solo":"","placeholder":"Password","name":"password","id":"password"},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.signupData.password),callback:function ($$v) {_vm.$set(_vm.signupData, "password", $$v)},expression:"signupData.password"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.showTerms)?_c('Terms'):_vm._e(),(_vm.showPrivacy)?_c('Privacy'):_vm._e(),_c('div',{staticClass:"tw-pt-4"},[_c('v-checkbox',{staticClass:"terms",attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"tw--mt-1"},[_vm._v("I agree to the "),_c('span',{staticClass:"tw-cursor-pointer",staticStyle:{"color":"#f784ad"},on:{"click":_vm.toggleTerms}},[_vm._v(" Terms of Use")]),_vm._v(" and "),_c('span',{staticClass:"tw-cursor-pointer",staticStyle:{"color":"#f784ad"},on:{"click":_vm.togglePrivacy}},[_vm._v(" Privacy Policy")])])]},proxy:true}],null,true),model:{value:(_vm.agree),callback:function ($$v) {_vm.agree=$$v},expression:"agree"}})],1),_c('base-button',{staticClass:"mt-4",attrs:{"disabled":!_vm.agree,"width":"100%","button-text":"Sign Up","loading":_vm.loading,"type":"submit"}}),_c('div',{staticClass:"tw-pt-4"},[_c('h6',{staticClass:"tw-flex already tw-items-center tw-justify-center"},[_vm._v(" Already have an account? "),_c('router-link',{staticStyle:{"color":"#004aad","cursor":"pointer"},attrs:{"to":{ name: 'Login' }}},[_vm._v(" Login")])],1)])],1)]}}])})],1)],1),_c('modal',{attrs:{"dialog":_vm.dialog,"title":"Thank you","additional-text":"We just sent you a confirmation email.\n      Please check your inbox to confirm your  email.","icon":_vm.check},on:{"close":_vm.toggleModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }